<template>
  <div class="appmain">
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>
<script>
import { jinanUserLogin } from "@/api/all";
import { getToken, setToken } from '@/utils/auth';
import { getUserInfoSafe } from '@/utils/Jsbridge';
import eruda from "eruda";
export default {
  data() {
    return {
      pathUrl: '',
      isIos: false
    }
  },
  watch: {
    $route(to, from) {
      this.pathUrl = to.fullPath;

    }
  },
  created() {
    // eruda.init();
  },
  mounted() {
  },
  methods: {
   
  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

p {
  font-size: 26px;
}

div {
  font-size: 26px;
}

/* 渐变设置 */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-active {
  transition: all 0.7s ease;
}

.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.6, 0.6, 1);
}
</style>
